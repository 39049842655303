import {
  PasswordInput as MantinePasswordInput,
  PasswordInputProps as MantinePasswordProps,
} from '@mantine/core'
import { useState } from 'react'

interface PasswordInputProps extends MantinePasswordProps {
  editing: boolean
  value: string
  handleChange?: any
}

const PasswordInput = ({
  editing,
  value,
  handleChange,
  ...props
}: PasswordInputProps) => {
  return (
    <MantinePasswordInput
      {...props}
      variant={editing ? 'default' : 'unstyled'}
      value={value ?? ''}
      readOnly={!editing}
      classNames={{
        label: 'text-sm font-medium text-doubleNickel-gray-700',
        input: '[&[data-disabled]]:border-none ',
        innerInput: 'bg-doubleNickel-white ',
      }}
      onChange={(event) => handleChange(event.target.value)}
    />
  )
}

export default PasswordInput
